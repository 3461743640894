import type React from 'react';
import classNames from 'classnames';
import Section from '../../Section/Section';
import styles from './InspirationBox.module.scss';
import Heading from '../../../../stories/Atoms/Heading/Heading';
import Picture from '../../Picture/Picture';
import Source from '../../Picture/Source';
import type { InspirationBox as InspirationBoxData } from '../ComponentTypes';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';

interface Props {
    component: InspirationBoxData;
}

const InspirationBox: React.FC<Props> = ({ component }) => {
    const withStaticUrl = useWithStaticUrl();

    return (
        <Section isXl>
            <div className={styles.InspirationBox}>
                <div className={classNames(styles.InspirationBox__box, styles.InspirationBox__box_text)}>
                    <Heading tag="h3" asText className={styles.InspirationBox__box__title}>
                        {component.textBox.text}
                    </Heading>
                </div>
                {component.imageBoxes.map((imageBox) => <a
                    key={imageBox.id}
                    className={classNames(styles.InspirationBox__box, styles.InspirationBox__box_image, styles.InspirationBox__box_circle)}
                    href={imageBox.url} aria-label={imageBox.text}>
                    {imageBox.imageUri && <Picture alt={imageBox.text || ''} imageUrl={withStaticUrl(imageBox.imageUri)} >
                        <Source imageWidth={375} minScreenWidth={768} />
                        <Source imageWidth={230} />
                    </Picture> }
                    {imageBox.text && <Heading tag="h4" asText className={styles.InspirationBox__box__title}>
                        {imageBox.text}
                    </Heading> }
                </a>)}
            </div>
        </Section>
    );
};

export default InspirationBox;
